.skills-page {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop {
  display: flex;
  justify-content: center;
  width: 90%;
  height: 100%;
}

.mobile {
  display: none;
}

.skills-page-container {
  margin-top: 10vh;
  width: 90%;
  background-color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 20px;
}

.skills-page-container h1 {
  color: white;
  font-size: 240%;
  margin: 0;
}

.skills-container {
  display: flex;
}

.skill {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 190px;
  position: relative;
  margin-right: 20px;
}

@keyframes iconAnimation {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.icon {
  color: white;
  font-size: 100px;
  position: absolute;
  right: 15%;
  animation: iconAnimation 2.5s ease-in-out infinite alternate;
}

.skill button {
  z-index: initial;
  width: 60%;
  height: 20%;
  position: absolute;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.37);
  backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.664);
  border-radius: 7px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

.skill button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 964px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: inline-block;
    width: 100%;
    height: auto;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-skill-container {
    border: 2px solid rgb(165, 156, 156);
    margin: 5px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .heading {
    font-size: 120%;
    text-align: center;
    margin: 20px 0px;
  }

  .mobile-skills {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .each-skill {
    padding: 0 5px;
    margin: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mobile-skill-name {
    font-size: 20px;
    color: rgb(61, 48, 48);
    right: 10px;
  }

  .each-skill p {
    font-size: 14px;
    font-weight: 500;
    color: rgb(170, 166, 166);
    margin-top: -10px;
    margin-bottom: 0;
  }
}
