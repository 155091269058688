@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Sora:wght@100..800&display=swap");

body {
  font-family: "Sora", sans-serif;
}

.page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
