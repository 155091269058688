.about-me {
  background-color: rgb(240, 240, 240);
  padding: 10px;
  width: 90%;
  height: 80%;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 2px solid black;
  border-radius: 20px;
}

.about-me h1 {
  font-size: 300%;
  user-select: none;
}

.about-me-name {
  font-size: 30px;
  font-weight: bold;
}

.about-me p {
  text-align: start;
  font-size: 130%;
  word-spacing: 5px;
  overflow-y: scroll;
}

.about-me p::-webkit-scrollbar {
  display: none;
}
