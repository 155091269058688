.home-page-container {
  margin-top: 10vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  width: 500px;
  height: 500px;
  background-image: url("../../assets/profile-image.jpg");
  background-size: cover;
  background-position: center;
  margin-right: 50px;
  border-radius: 50%;
}

.image-container:hover {
  box-shadow: 5px 5px 10px red;
  transition: 0.3s;
}

.info-container {
  width: 900px;
  height: 500px;
  margin-left: 50px;
  text-align: center;
}

.info-container h3 {
  font-size: 20px;
  color: black;
  opacity: 0.7;
  z-index: -1;
}

.name {
  font-size: 70px;
  margin-top: -5px;
}

.profession {
  font-size: 70px;
  margin-top: -10px;
  font-weight: bolder;
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.developer-text {
  font-size: 75px;
  background: linear-gradient(
    to right,
    #7953cd 20%,
    #00affa 30%,
    #0190cd 70%,
    #764ada 80%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}

.resume {
  font-size: 20px;
  font-weight: 600;
}

.contact-me {
  font-size: 20px;
  font-weight: 600;
}

.button {
  width: 150px;
  height: 50px;
  border-radius: 30px;
  margin: 10px;
  background-color: transparent;
  border: 2px solid black;
  transition: transform 0.2s;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  color: black;
  text-decoration: none;
}

.button:hover {
  transform: translateY(-5px);
  color: white;
  background-color: black;
  box-shadow: 5px 0px 10px rgb(49, 0, 105);
}

@media screen and (max-width: 964px) {
  .home-page-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    margin-bottom: 20px;
    width: 200px !important;
    height: 200px !important;
  }

  .info-container {
    width: 100% !important;
    height: auto;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .name {
    font-size: 40px;
  }

  .profession {
    font-size: 40px;
  }

  .developer-text {
    font-size: 40px;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1419px) {
  .image-container {
    width: 400px;
    height: 400px;
    margin-left: 50px;
  }

  .info-container {
    width: 40%;
  }
}
