.mobile-swiper {
  display: none;
}

.projects-page {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-image {
  width: 90%;
  height: 50%;
}

.project-description {
  padding: 10px;
  font-size: 20px;
}

.project-buttons-container {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.project-git-button {
  background-color: black;
  color: white;
  width: 100px;
  margin-right: 20px;
  border-radius: 10px;
}

.project-git-button:hover {
  background-color: white;
  color: black;
}

.project-demo-button {
  background-color: rgb(91, 196, 238);
  color: white;
  width: 100%;
  border-radius: 10px;
}

.project-demo-button:hover {
  background-color: rgb(19, 177, 240);
}

.direction-button {
  border: none;
  height: 120px;
}

.direction-button:hover {
  background-color: rgba(0, 0, 0, 0.308);
  color: black;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.308);
}

swiper-container {
  width: 80%;
  height: 100%;
  margin-top: 50px;
  z-index: 0;
}

swiper-slide {
  width: 50%;
  height: 90%;
  position: relative;
  top: 2%;
  box-shadow: 0px 5px 10px black;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

swiper-slide img {
  width: 100%;
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  .desktop-swiper {
    display: none;
  }

  .mobile-swiper {
    display: inline;
  }

  .project-description {
    padding: 10px;
    font-size: 15px;
  }

  swiper-container {
    width: 100vw;
    height: auto;
    margin-top: 0;
    text-align: center;
  }

  swiper-slide {
    width: 90% !important;
    height: 85%;
    margin: 0 20px;
  }

  .project-buttons-container {
    width: 210px;
  }

  .project-git-button {
    width: 100px;
    height: 40px;
  }

  .project-demo-button {
    width: 100px;
    border: none;
    height: 40px;
  }
}
