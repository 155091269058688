.navigation-bar {
  background-color: white;
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1;
}

@keyframes dot {
  0% {
    background-color: rgb(0, 0, 0);
    transform: scale(0, 0);
  }
  100% {
    border: 2px solid black;
    transform: scale(1, 1);
  }
}

.dot {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  animation-name: dot;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.profile-button {
  width: 150px;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  -webkit-tap-highlight-color: transparent;
  background-color: white;
  position: relative;
}

.profile-button:hover {
  border-radius: 20px;
  box-shadow: 1px 10px 20px rgba(97, 95, 95, 0.6);
  transition: 0.3s;
}

.navigation-bar button:nth-child(2) {
  display: none;
}

.links {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.hamburger-icon-button {
  display: none;
}

.link {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin-right: 20px;
  -webkit-tap-highlight-color: transparent;
}

.link:hover {
  cursor: pointer;
  box-shadow: 1px 10px 20px rgba(97, 95, 95, 0.6);
  transition: 0.3s;
  border-radius: 20px;
}

@media screen and (max-width: 832px) {
  .profile-button {
    display: none;
  }

  .navigation-bar {
    width: 100%;
    height: 6vh;
    padding-top: 10px;
  }

  .links {
    display: none;
  }

  .navigation-bar button:nth-child(1) {
    display: none;
  }

  .navigation-bar button:nth-child(2) {
    display: inline;
    border: 2px solid black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
  }

  .navigation-bar button:nth-child(2) img {
    width: 70px;
    height: 73px;
    border-radius: 50%;
    margin-left: 20px;
    z-index: 0;
  }

  .hamburger-icon-button {
    display: inline;
    z-index: 2;
    font-size: 40px;
    margin-right: 20px;
    background-color: transparent;
    border: none;
  }

  @keyframes collapsableSideBar {
    0% {
      transform: translateX(50%);
    }
    100% {
      text-replace: translateX(-50%);
    }
  }

  .collapsable-side-bar {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    box-shadow: -1px 0px 20px black;
    height: 100vh;
    width: 60%;
    position: fixed;
    top: -2vh;
    right: 0;
    padding-top: 25%;
    animation: collapsableSideBar 0.3s alternate;
    list-style-type: none;
    z-index: 1;
  }

  .collapsable-side-bar li {
    font-size: 25px;
    padding: 10px 0;
    user-select: none;
  }
}

@media screen and (max-width: 1419px) {
  .links {
    width: 60%;
  }

  .link {
    width: 200px;
    height: 50px;
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
  }
}
