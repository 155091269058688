.contact-me-container {
  width: 90vw;
  height: 80vh;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container {
  border: 2px solid gray;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
}

.form-container label {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  font-size: 30px;
}

.form-container label input {
  background-color: transparent;
  outline: none;
  border: 2px solid black;
  border-radius: 4px;
  margin-top: 20px;
  height: 40px;
  font-size: 20px;
  text-align: center;
}

.form-container label input::placeholder {
  color: black;
  opacity: 0.3;
}

textarea {
  background-color: transparent;
  font-family: "Outfit", sans-serif;
  border: 2px solid black;
  border-radius: 4px;
  height: 200px;
  resize: none;
  font-size: 20px;
  outline: none;
}

textarea::placeholder {
  color: black;
  font-family: "Outfit", sans-serif;
  padding: 2px;
  opacity: 0.3;
}

button {
  padding: 5px;
  background-color: transparent;
  border: 2px solid black;
  border-radius: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
}

button:hover {
  cursor: pointer;
  background-color: black;
  color: white;
  transition: 0.4s;
}

.submit-message {
  color: green;
  font-weight: bold;
}

.link-icon {
  margin-right: 10px;
}

.my-contact-links a {
  margin: 20px;
  font-size: 40px;
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.my-contact-links a:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
}

@media screen and (max-width: 600px) {
  .my-contact-links a {
    margin: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .my-contact-links {
    margin-bottom: 50px;
  }
}
